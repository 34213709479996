import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { getChainInfo } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { ArrowUpRight } from 'react-feather'
import { AlertTriangle } from 'react-feather'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ExternalLink, HideSmall } from 'theme'

import { AutoRow } from '../Row'

const L2Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

const BodyText = styled.div`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px;
  font-size: 14px;
`
const RootWrapper = styled.div`
  position: relative;
  margin-top: 16px;
`

const SHOULD_SHOW_ALERT = {
  // [SupportedChainId.OPTIMISM]: true,
  // [SupportedChainId.OPTIMISM_GOERLI]: true,
  // [SupportedChainId.ARBITRUM_ONE]: true,
  // [SupportedChainId.ARBITRUM_RINKEBY]: true,
  // [SupportedChainId.POLYGON]: true,
  // [SupportedChainId.POLYGON_MUMBAI]: true,
  // [SupportedChainId.CELO]: true,
  // [SupportedChainId.CELO_ALFAJORES]: true,
  // [SupportedChainId.SCROLL_ALPHA]: true,
  [SupportedChainId.SCROLL_SEPOLIA]: true,
}

type NetworkAlertChains = keyof typeof SHOULD_SHOW_ALERT

const BG_COLORS_BY_DARK_MODE_AND_CHAIN_ID: {
  [darkMode in 'dark' | 'light']: { [chainId in NetworkAlertChains]: string }
} = {
  dark: {
    // [SupportedChainId.POLYGON]:
    //   'radial-gradient(100% 93.36% at 0% 6.64%, rgba(160, 108, 247, 0.1) 0%, rgba(82, 32, 166, 0.1) 100%)',
    // [SupportedChainId.POLYGON_MUMBAI]:
    //   'radial-gradient(100% 93.36% at 0% 6.64%, rgba(160, 108, 247, 0.1) 0%, rgba(82, 32, 166, 0.1) 100%)',
    // [SupportedChainId.CELO]:
    //   'radial-gradient(182.71% 150.59% at 2.81% 7.69%, rgba(90, 190, 170, 0.15) 0%, rgba(80, 160, 40, 0.15) 100%)',
    // [SupportedChainId.CELO_ALFAJORES]:
    //   'radial-gradient(182.71% 150.59% at 2.81% 7.69%, rgba(90, 190, 170, 0.15) 0%, rgba(80, 160, 40, 0.15) 100%)',
    // [SupportedChainId.OPTIMISM]:
    //   'radial-gradient(948% 292% at 42% 0%, rgba(255, 58, 212, 0.01) 0%, rgba(255, 255, 255, 0.04) 100%),radial-gradient(98% 96% at 2% 0%, rgba(255, 39, 39, 0.01) 0%, rgba(235, 0, 255, 0.01) 96%)',
    // [SupportedChainId.OPTIMISM_GOERLI]:
    //   'radial-gradient(948% 292% at 42% 0%, rgba(255, 58, 212, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%),radial-gradient(98% 96% at 2% 0%, rgba(255, 39, 39, 0.04) 0%, rgba(235, 0, 255, 0.01 96%)',
    // [SupportedChainId.ARBITRUM_ONE]:
    //   'radial-gradient(285% 8200% at 30% 50%, rgba(40, 160, 240, 0.01) 0%, rgba(219, 255, 0, 0) 100%),radial-gradient(75% 75% at 0% 0%, rgba(150, 190, 220, 0.05) 0%, rgba(33, 114, 229, 0.05) 100%), hsla(0, 0%, 100%, 0.05)',
    // [SupportedChainId.ARBITRUM_RINKEBY]:
    //   'radial-gradient(285% 8200% at 30% 50%, rgba(40, 160, 240, 0.05) 0%, rgba(219, 255, 0, 0) 100%),radial-gradient(75% 75% at 0% 0%, rgba(150, 190, 220, 0.05) 0%, rgba(33, 114, 229, 0.1) 100%), hsla(0, 0%, 100%, 0.05)',
    // [SupportedChainId.SCROLL_ALPHA]:
    //   'radial-gradient(100% 93.36% at 0% 6.64%, rgba(120, 128, 247, 0.1) 0%, rgba(62, 62, 160, 0.1) 100%)',
    [SupportedChainId.SCROLL_SEPOLIA]:
      'radial-gradient(100% 93.36% at 0% 6.64%, rgba(120, 128, 247, 0.1) 0%, rgba(62, 62, 160, 0.1) 100%)',
  },
  light: {
    // [SupportedChainId.POLYGON]:
    //   'radial-gradient(182.71% 205.59% at 2.81% 7.69%, rgba(130, 71, 229, 0.2) 0%, rgba(167, 202, 255, 0.2) 100%)',
    // [SupportedChainId.POLYGON_MUMBAI]:
    //   'radial-gradient(182.71% 205.59% at 2.81% 7.69%, rgba(130, 71, 229, 0.2) 0%, rgba(167, 202, 255, 0.2) 100%)',
    // [SupportedChainId.CELO]:
    //   'radial-gradient(182.71% 150.59% at 2.81% 7.69%, rgba(63, 208, 137, 0.15) 0%, rgba(49, 205, 50, 0.15) 100%)',
    // [SupportedChainId.CELO_ALFAJORES]:
    //   'radial-gradient(182.71% 150.59% at 2.81% 7.69%, rgba(63, 208, 137, 0.15) 0%, rgba(49, 205, 50, 0.15) 100%)',
    // [SupportedChainId.OPTIMISM]:
    //   'radial-gradient(92% 105% at 50% 7%, rgba(255, 58, 212, 0.04) 0%, rgba(255, 255, 255, 0.03) 100%),radial-gradient(100% 97% at 0% 12%, rgba(235, 0, 255, 0.1) 0%, rgba(243, 19, 19, 0.1) 100%), hsla(0, 0%, 100%, 0.1)',
    // [SupportedChainId.OPTIMISM_GOERLI]:
    //   'radial-gradient(92% 105% at 50% 7%, rgba(255, 58, 212, 0.04) 0%, rgba(255, 255, 255, 0.03) 100%),radial-gradient(100% 97% at 0% 12%, rgba(235, 0, 255, 0.1) 0%, rgba(243, 19, 19, 0.1) 100%), hsla(0, 0%, 100%, 0.1)',
    // [SupportedChainId.ARBITRUM_ONE]:
    //   'radial-gradient(285% 8200% at 30% 50%, rgba(40, 160, 240, 0.1) 0%, rgba(219, 255, 0, 0) 100%),radial-gradient(circle at top left, hsla(206, 50%, 75%, 0.01), hsla(215, 79%, 51%, 0.12)), hsla(0, 0%, 100%, 0.1)',
    // [SupportedChainId.ARBITRUM_RINKEBY]:
    //   'radial-gradient(285% 8200% at 30% 50%, rgba(40, 160, 240, 0.1) 0%, rgba(219, 255, 0, 0) 100%),radial-gradient(circle at top left, hsla(206, 50%, 75%, 0.01), hsla(215, 79%, 51%, 0.12)), hsla(0, 0%, 100%, 0.1)',
    // [SupportedChainId.SCROLL_ALPHA]:
    //   'radial-gradient(182.71% 205.59% at 2.81% 7.69%,#EEE2D4FF 0%, #E4D5C3FF 100%)',
    [SupportedChainId.SCROLL_SEPOLIA]:
      'radial-gradient(182.71% 205.59% at 2.81% 7.69%,#EEE2D4FF 0%, #E4D5C3FF 100%)',
  },
}

const ContentWrapper = styled.div<{ chainId: NetworkAlertChains; darkMode: boolean; logoUrl: string }>`
  background: ${({ chainId, darkMode }) => BG_COLORS_BY_DARK_MODE_AND_CHAIN_ID[darkMode ? 'dark' : 'light'][chainId]};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  width: 100%;

  :before {
    background-image: url(${({ logoUrl }) => logoUrl});
    background-repeat: no-repeat;
    background-size: 300px;
    content: '';
    height: 300px;
    opacity: 0.1;
    position: absolute;
    transform: rotate(25deg) translate(-90px, -40px);
    width: 300px;
    z-index: -1;
  }
`
const Header = styled.h2`
  font-weight: 600;
  font-size: 16px;
  margin: 0;
`

const LinkOutToBridge = styled(ExternalLink)`
  align-items: center;
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 6px 8px;
  margin-right: 12px;
  text-decoration: none !important;
  width: 100%;
`

const StyledArrowUpRight = styled(ArrowUpRight)`
  margin-left: 12px;
  width: 24px;
  height: 24px;
`

const TEXT_COLORS: { [chainId in NetworkAlertChains]: string } = {
  // [SupportedChainId.POLYGON]: 'rgba(130, 71, 229)',
  // [SupportedChainId.POLYGON_MUMBAI]: 'rgba(130, 71, 229)',
  // [SupportedChainId.CELO]: 'rgba(53, 178, 97)',
  // [SupportedChainId.CELO_ALFAJORES]: 'rgba(53, 178, 97)',
  // [SupportedChainId.OPTIMISM]: '#ff3856',
  // [SupportedChainId.OPTIMISM_GOERLI]: '#ff3856',
  // [SupportedChainId.ARBITRUM_ONE]: '#0490ed',
  // [SupportedChainId.ARBITRUM_RINKEBY]: '#0490ed',
  // [SupportedChainId.SCROLL_ALPHA]: 'rgba(113, 98, 124)',
  [SupportedChainId.SCROLL_SEPOLIA]: 'rgba(113, 98, 124)',
}

function shouldShowAlert(chainId: number | undefined): chainId is NetworkAlertChains {
  return Boolean(chainId && SHOULD_SHOW_ALERT[chainId as unknown as NetworkAlertChains])
}

export function NetworkAlert() {
  const { chainId } = useWeb3React()
  const [darkMode] = useDarkModeManager()

  if (!shouldShowAlert(chainId)) {

    // const textColor = TEXT_COLORS[SupportedChainId.SCROLL_ALPHA]
    const textColor = TEXT_COLORS[SupportedChainId.SCROLL_SEPOLIA]

    return (
      <RootWrapper>
        <ContentWrapper chainId={SupportedChainId.SCROLL_SEPOLIA} darkMode={darkMode} logoUrl="">
          <LinkOutToBridge href="https://snapshot.org/#/uniswap/proposal/0x5ad7208d9ccad93dbc96abc12bfeffcfe83e96b9782e5135727cdaaf494d70f3">
            <BodyText color={darkMode ? textColor : "black"}>
              <AlertTriangle size={24} style={{ marginRight: "16px" }} />
              <AutoRow>
                <Header>
                  <Trans>Intended for Demo Purposes Only</Trans>
                </Header>
                <HideSmall>
                  <Trans>This is not an official Uniswap release.</Trans>
                </HideSmall>
              </AutoRow>
            </BodyText>
            <StyledArrowUpRight color={darkMode ? textColor : "black"} />
          </LinkOutToBridge>
        </ContentWrapper>
      </RootWrapper>

    )
  }

  const { label, logoUrl, bridge } = getChainInfo(chainId)
  const textColor = TEXT_COLORS[chainId]

  return bridge ? (
    <RootWrapper>
      <ContentWrapper chainId={chainId} darkMode={darkMode} logoUrl={logoUrl}>
        <LinkOutToBridge href={bridge}>
          <BodyText color={textColor}>
            <L2Icon src={logoUrl} />
            <AutoRow>
              <Header>
                <Trans>{label} token bridge</Trans>
              </Header>
              <HideSmall>
                <Trans>Deposit tokens to the {label} network.</Trans>
              </HideSmall>
            </AutoRow>
          </BodyText>
          <StyledArrowUpRight color={textColor} />
        </LinkOutToBridge>
      </ContentWrapper>
      <div style={{ height: "16px" }} />
      <ContentWrapper chainId={chainId} darkMode={darkMode} logoUrl={logoUrl}>
        <LinkOutToBridge href="https://snapshot.org/#/uniswap/proposal/0x5ad7208d9ccad93dbc96abc12bfeffcfe83e96b9782e5135727cdaaf494d70f3">
          <BodyText color={textColor}>
            {/* <L2Icon src={logoUrl} /> */}
            <AlertTriangle size={24} style={{ marginRight: "16px" }} />
            <AutoRow>
              <Header>
                <Trans>Intended for Demo Purposes Only</Trans>
              </Header>
            </AutoRow>
          </BodyText>
          <StyledArrowUpRight color={textColor} />
        </LinkOutToBridge>
      </ContentWrapper>
    </RootWrapper>
  ) : null
}
